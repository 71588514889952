<!--
 * @Description: 车行道停车运营管理 员工管理 班次管理 classesManagement    
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="班次名称">
              <el-input v-model="searchForm.shiftName"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>

    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>班次管理</span>
        <!--        <el-button class="tableTitleButton"-->
        <!--                   type="success">导出</el-button>-->
        <el-button class="tableTitleButton"
                   type="success"
                   @click="addHandle">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  ref="tableRef"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
            <!-- <el-table-column type="selection"></el-table-column> -->
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleDelete(scope.row)">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog title="新增班次"
               :visible.sync="dialogVisible"
               append-to-body>
      <!-- 新增班次表单 -->
      <el-form label-width="100px"
               :model="addForm"
               ref="addForm"
               :rules="rules"
               :inline="true"
               size="small">
        <el-row justify="space-around"
                class="firstLine">
          <el-col :span="12">
            <el-form-item label="班次名称"
                          prop="shiftName">
              <el-input v-model="addForm.shiftName"
                        placeholder="请输入"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班次描述"
                          prop="remark">
              <el-input v-model="addForm.remark"
                        placeholder="请输入"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="早班时间"
                          prop="dayShift">
              <!-- <el-time-picker is-range
                              v-model="addForm.dayShift"
                              range-separator="至"
                              start-placeholder="开始时间"
                              end-placeholder="结束时间"
                              value-format="HH:mm"
                              format="HH:mm"
                              class="dt-form-width">
              </el-time-picker> -->

              <el-time-select style="width:130px;"
                              :clearable="false"
                              placeholder="起始时间"
                              v-model="addForm.dayBeginShift"
                              :picker-options="{
              start: '00:00',
              step:'00:10',
              end:addForm.dayEndShift?addForm.dayEndShift:'23:59',
              maxTime: addForm.dayEndShift
          }">
              </el-time-select>
              <el-time-select style="width:130px;"
                              placeholder="结束时间"
                              :clearable="false"
                              v-model="addForm.dayEndShift"
                              :picker-options="{
              start:addForm.dayBeginShift?addForm.dayBeginShift:'00:00',
              step:'00:10',
              end:'24:00',
              minTime: addForm.dayBeginShift
            }">
              </el-time-select>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="午班时间"
                          prop="midShift">
              <!-- <el-time-picker is-range
                              v-model="addForm.midShift"
                              range-separator="至"
                              start-placeholder="开始时间"
                              end-placeholder="结束时间"
                              value-format="HH:mm"
                              format="HH:mm"
                              class="dt-form-width">
              </el-time-picker> -->
              <el-time-select style="width:130px;"
                              :clearable="false"
                              placeholder="起始时间"
                              v-model="addForm.midBeginShift"
                              :picker-options="{
              start:addForm.dayEndShift?addForm.dayEndShift:'00:00',
              step:'00:10',
              end:'24:00',
              
          }">
              </el-time-select>
              <el-time-select style="width:130px;"
                              placeholder="结束时间"
                              :clearable="false"
                              v-model="addForm.midEndShift"
                              :picker-options="{
              start:addForm.midBeginShift?addForm.midBeginShift:'00:00',
              step:'00:10',
              end:'24:00',
             maxTime: addForm.nightBeginShift
            }">
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="晚班时间"
                          prop="nightShift">
              <!--  <el-time-picker is-range
                              v-model="addForm.nightShift"
                              range-separator="至"
                              start-placeholder="开始时间"
                              end-placeholder="结束时间"
                              value-format="HH:mm"
                              format="HH:mm"
                              class="dt-form-width">
              </el-time-picker> -->
              <el-time-select style="width:130px;"
                              :clearable="false"
                              placeholder="起始时间"
                              v-model="addForm.nightBeginShift"
                              :picker-options="{
                                start:addForm.midEndShift?addForm.midEndShift:'00:00',
                                step:'00:10',
                                 end:'24:00',
          }">
              </el-time-select>
              <el-time-select style="width:130px;"
                              placeholder="结束时间"
                              :clearable="false"
                              v-model="addForm.nightEndShift"
                              :picker-options="{
                                start:addForm.nightBeginShift?addForm.nightBeginShift:'00:00',
                                step:'00:10',
                                end:'24:00',
                                minTime: addForm.nightBeginShift
            }">
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="handleClose('addForm')">确 定</el-button>
          <el-button @click="cancel()"
                     type="primary"
                     style="margin-left:17px;">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      searchForm: {}, // 搜索内容
      pageNum: 1, // 分页
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'shiftName',
          label: '班次名称',
        },
        {
          prop: 'remark',
          label: '班次描述',
        },
        {
          prop: 'dayShift',
          label: '早班时段',
        },
        {
          prop: 'midShift',
          label: '中班时段',
        },
        {
          prop: 'nightShift',
          label: '晚班时段',
        },
      ],

      //提交新增表单
      addForm: {
        shiftName: '',
        remark: '',
        dayBeginShift: '',//早班开始时间
        dayEndShift: '',//早班结束时间
        midBeginShift: '',//午班开始时间
        midEndShift: '',//午班结束时间
        nightBeginShift: '',//晚班开始时间
        nightEndShift: '',//晚班结束时间
      },
      dialogVisible: false,//弹框显示属性
      rules: {
        shiftName: [
          { required: true, message: '请输入班次名称', trigger: 'blur' },
          { min: 1, max: 32, message: '请输入1-32个中文', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入班次描述', trigger: 'blur' },
          { min: 1, max: 32, message: '请输入1-32个中文', trigger: 'blur' }
        ],
      }
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
  },
  //方法集合
  methods: {
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$classesManagement.classManagementGetList(this.searchForm).then((response) => {
        this.tableList.list = response.resultEntity.list
        this.tableList.list.forEach(item => {
          item.dayShift = item.dayBeginShift + '-' + item.dayEndShift
          item.midShift = item.midBeginShift + '-' + item.midEndShift
          item.nightShift = item.nightBeginShift + '-' + item.nightEndShift
        });
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList();
    },
    //查询表单
    onSubmit () {
      let Pager = this.$refs.tableRef.$children[1]
      Pager.internalCurrentPage = 1
      this.queryTableList()
    },
    //重置表单
    resetForm () {
      this.searchForm = {}
      this.queryTableList()
    },
    //新增按钮
    addHandle () {
      this.dialogVisible = true
      this.addForm = {
        shiftName: '',
        remark: '',
        dayBeginShift: '',//早班开始时间
        dayEndShift: '',//早班结束时间
        midBeginShift: '',//午班开始时间
        midEndShift: '',//午班结束时间
        nightBeginShift: '',//晚班开始时间
        nightEndShift: '',//晚班结束时间
      }
    },
    //新增接口
    insert () {
      this.$classesManagement.classManagementInsert(this.addForm).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.dialogVisible = false
          this.queryTableList()
          this.addForm = {}
        }
      })
    },
    //弹出框确认
    handleClose () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.insert()
        } else {
          return false;
        }
      });
    },
    //新增取消
    cancel () {
      this.dialogVisible = false
      this.addForm = {}
    },
    //删除
    handleDelete (row) {
      console.log(row);
      this.$confirm('确认删除' + row.shiftName + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [{ shiftId: row.shiftId }]
        this.$classesManagement.classManagementDelete(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          }
        })
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}

.firstLine {
  margin-top: 10px;
}
.dt-form-width {
  width: 230px;
  margin-top: 5px;
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>
